import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import './LinkButton.css';

const LinkButton = ({ text, bg, color, link }) => {
    return (
        <div className="link-btn-div">
            <a
                target="_blank"
                href={link}
                style={{ background: bg, color: color }}
                className="link-btn"
                rel="noreferrer"
            >
                <span>{text}</span>
                <span className="arrow-right">
                    <BsArrowRightShort />
                </span>
            </a>
        </div>
    );
};

export default LinkButton;
