import cardImg from '../../../images/app2.png';
import hero2 from '../../../images/payment-method1.png';
import CardStyleTwo from '../../Shared/CardStyleTwo/CardStyleTwo';
import UpcomingPartners from '../../Shared/UpcomingPartners/UpcomingPartners';
import CardWidget1 from './CardWidget1/CardWidget1';
import CardWidget2 from './CardWidget2/CardWidget2';
import CardWidget3 from './CardWidget3/CardWidget3';
import './Payments.css';

const Payments = () => {
    const widget = {
        cardTitle: 'Get paid instantly in your local bank account',
        cardImg: cardImg,
        direction: 'row',
        cardBG: '#ffffff',
        cardHColor: '#040415',
        cardPColor: '#04041599',
    };

    return (
        <>
            <div className="section-margin">
                <div className="payments-area">
                    <div className="container">
                        <div className="payments-hero">
                            <div className="payments-hero-left">
                                <h1>Get paid instantly in your local currency</h1>
                                <p>
                                    Request money from your clients with just a click. Create your
                                    invoices based on your services to get payments from your
                                    customers.
                                </p>
                                <div className="button-area">
                                    <a href="https://revoweb.web.app/" className="hero-button">
                                        Get Started
                                    </a>
                                </div>
                            </div>
                            <div className="payments-hero-right">
                                <img src={hero2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UpcomingPartners />
            <div className="section-margin">
                <CardWidget1 />
                <br />
                <br />
                <CardWidget2 />
                <br />
                <br />
                <CardWidget3 />
            </div>
            <div className="section-margin">
                <CardStyleTwo widget={widget} />
            </div>
        </>
    );
};

export default Payments;
