import React from 'react';
import './ProcessTabContent.css';

const ProcessTabContent = ({ title, content, tabImg }) => {
    return (
        <div className="tab-content">
            <div className="tab-content-left">
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
            <div className="tab-content-right">
                <img src={tabImg} alt="img" />
            </div>
        </div>
    );
};

export default ProcessTabContent;
