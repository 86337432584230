import React from 'react';
// import { AiFillApple } from 'react-icons/ai';
import appImg from '../../../images/app.png';
// import bdIcon from '../../../images/bd.svg';
import './Hero.css';

const appUserURL = process.env.REACT_APP_USER_APP_URL;
const Hero = () => {
    return (
        <>
            <div className="hero-section">
                <div className="container">
                    <div className="hero-area">
                        <div className="hero-left">
                            <h1>
                                Get paid from anywhere <br />
                                in the world as a freelancer
                            </h1>
                            <p>
                                Request money from your clients with just a click. Create your
                                invoices based on your services to get payments from your customers.
                            </p>
                            {/* <h3>Enter your number to get the app</h3>
                        <div className="number-field">
                            <div className="number-field-left">
                                <img width="20" src={bdIcon} alt="Icon" />
                                <span>+880</span>
                            </div>
                            <div className="number-field-right">
                                <input type="text" value={mobileNumber} onChange={changeHandler} />
                                {mobileNumber && (
                                    <span onClick={() => setMobileNumber('')}>
                                        <RiCloseCircleLine />
                                    </span>
                                )}
                            </div>
                        </div> */}
                            <div className="button-area">
                                <a href={appUserURL} className="hero-button">
                                    Get Started
                                </a>
                                {/*  <a className="download-button">
                                <span>Download App</span>
                                <span className="app-icon">
                                    <AiFillApple />
                                    <RiGooglePlayFill />
                                </span>
                            </a> */}
                            </div>
                        </div>
                        <div className="hero-right">
                            <img src={appImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
