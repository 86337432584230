import React from 'react';
import cardImg from '../../../images/payment-method2.png';
import CardStyleOne from '../../Shared/CardStyleOne/CardStyleOne';

const CardWidget2 = () => {
    const widget = {
        cardTitle: 'Get a little more than just receiving payments',
        cardContent:
            'Do not let the clients leave your websites to pay for your services and products; in most of cases you end up losing your valuable customers. Accept payment from your website with different payment methods that suit your clients with Revorium’s global payment API. Read the payment API integration guide.',
        cardImg: cardImg,
        direction: 'row-reverse',
        cardBG: '#ffffff',
        cardHColor: '#040415cc',
        cardPColor: '#04041599',
    };

    const linkBtn = {
        text: 'Learn More',
        btnBG: '#5947FD42',
        btnColor: '#040415',
        link: '/receiving-payment',
    };

    return (
        <>
            <CardStyleOne widget={widget} linkBtn={linkBtn} />
        </>
    );
};

export default CardWidget2;
