import './CookiePolicy.css';

const CookiePolicy = () => {
    return (
        <>
            <div className="container">
                <div className="content-page-area">
                    <h2>Cookie Policy</h2>

                    <strong>What are cookies and what do they do?</strong>
                    <p>
                        Cookies are text files that are placed on websites on the internet. Each
                        cookie includes the name of the website and a unique ID. We use cookies on
                        this website. Cookies track things like how long a visitor spends on our
                        website and what visitors do on our website (which pages they visit and
                        which buttons they click on, for example). Cookies collect this information
                        and share it with us, the website owner.
                    </p>
                    <p>
                        Cookies help us understand who uses our website and how we can improve it.
                        For instance, we can understand which pages of our website are most popular
                        with certain kinds of visitors. This allows us to understand what visitors
                        like about our website and how we can make it more attractive for other
                        visitors.
                    </p>
                    <strong>How do we use cookies?</strong>
                    <p>
                        As we set out above, we use cookies on the Revorium website. Cookies help us
                        to understand how our users interact with our website. Most of the cookies
                        we use on our website are 'persistent' cookies. This means that they remain
                        on your device until you erase them or they expire. The rest of these
                        cookies are 'session-based which is more temporary in duration. This means
                        they will be erased when you close your internet browser.
                    </p>

                    <strong>We use the following types of cookies:</strong>
                    <p>
                        <label>
                            <b>- Necessary cookies:</b>
                        </label>
                        Necessary cookies are placed on our website by default. You can turn off
                        these cookies on your device if you don't want us to use necessary cookies
                        but it might impact your ability to browse our website. We need the
                        necessary cookies to help us to run our website, and to help us detect any
                        problems with it. For example, we use a cookie that tells us how busy the
                        server is based on the number of visitors and that tells us if any users
                        experience problems so that we can resolve the problems as soon as possible.
                    </p>
                    <p>
                        We also use cookies to remember our users while they are browsing our
                        website, its pages, links, and subdomains. For example, we use necessary
                        cookies to help recognize you and provide you with content as you browse the
                        website. We may also use a cookie that tracks your cookie privacy
                        preferences so that we do not place analytics and advertising cookies, which
                        we'll talk about below if you don't want us to. This particular type of
                        cookie will remain on your device even after you've stopped browsing the
                        website so we can make sure we comply with our legal and regulatory
                        obligations.
                    </p>
                    <p>
                        <label>
                            <b>- Analytics and advertising cookies:</b>
                        </label>
                        These allow us to recognize and count the number of visitors to our website,
                        and see how visitors browse our website, so we can understand how our
                        website content is performing and improve it where necessary. We use
                        third-party cookies for this purpose and we've explained in the table below
                        the specific cookies we use. Advertising and analytics cookies also allow us
                        to see what pages, links, and sub-domains visitors have visited so we can
                        provide more relevant ads. We may share this information with other
                        organizations, such as Google, Facebook, and LinkedIn, for the same purpose.
                        Please see the section below for more detail about which advertising and
                        analytics cookies we use and why.
                    </p>
                    <strong>What cookies do we use?</strong>
                    <p>
                        These are the advertising and analytics cookies we use on our website and
                        the purposes for which we use them:
                    </p>
                    <strong>- BounceX</strong>
                    <p>
                        A cookie that tracks when a user is about to leave the Revorium website. It
                        asks them to provide their email address so we can tell them a bit more
                        about Revorium and our promotions.
                    </p>
                    <strong>- Branch</strong>
                    <p>
                        Enables us to detect when a user has arrived on the Revorium website by
                        clicking on a Revorium ad on another site or as a result of a referral or
                        reward advertised on another site.
                    </p>
                    <strong>- Facebook Ads</strong>
                    <p>
                        ells us how our ads on Facebook are working and what kinds of users are
                        interested in Revorium.
                    </p>
                    <strong>- Lever</strong>
                    <p>
                        To record page views relating to careers, and inform us if someone applies
                        for a job through the careers page of the Revorium website.
                    </p>
                    <strong>- Google Ads</strong>
                    <p>
                        A cookie that shows us how effective our Revorium ad campaigns are by
                        tracking how many users click through to our website from a particular ad,
                        and the demographic of those users.
                    </p>
                    <strong>- Google Analytics</strong>
                    <p>
                        Allows us to see how many users are on the Revorium website, which pages
                        they are visiting, and track how many visitors joined Revorium.
                    </p>
                    <strong>- Google Optimise</strong>
                    <p>
                        Google Optimise is Part of our testing platform to help us understand
                        whether any changes we've made to Revorium website pages have been well
                        received.
                    </p>
                    <strong>- Google Tag Manager</strong>
                    <p>This cookie controls other cookies on the website.</p>

                    <strong>- Intercom</strong>
                    <p>
                        Intercom is a tool that allows users to chat to Support through our website.
                        It uses a script with a visitor cookie associated with it.
                    </p>
                    <strong>- LinkedIn Insight</strong>
                    <p>
                        Tells us how often people are clicking on our ads on LinkedIn and are taken
                        to the jobs board on the Revorium website.
                    </p>
                    <strong>- Taboola Pixel</strong>
                    <p>
                        Tells us about your page visits and actions (clicks & conversion) on our
                        website when you have engaged with our marketing content on other websites.
                        We use this information to measure the effectiveness of the marketing
                        content we publish.
                    </p>
                    <strong>- TV Squared Pixels</strong>
                    <p>
                        Tell us the number of visitors who come to our website when we run TV
                        advertising campaigns so that we can measure the effectiveness of these
                        campaigns.
                    </p>
                    <strong>- Can I change my preferences?</strong>
                    <p>
                        Yes. You can manage your cookies preferences by visiting this page. You can
                        also manage your cookie preferences through our pop-up cookie banner that
                        appears when you first visit the website. You can also manage your
                        preferences by changing your browser settings on your device to refuse the
                        use of all or some cookies. However, if you block all cookies (including
                        necessary cookies), you may not be able to use all or some parts of our
                        website. You may also find that you are shown information on our website
                        that is less interesting to you.
                    </p>
                    <p>
                        Please note, that other organizations, such as advertising networks, may
                        also use cookies to track you across different websites. We have no control
                        over these cookies. We'll let you know if we change what cookies we use If
                        we change the cookies we use, we will update this Cookies Policy. You always
                        can find the latest version of this Policy on our website.
                    </p>
                </div>
            </div>
        </>
    );
};

export default CookiePolicy;
