import tabImg from '../../../../../images/get-verified.png';
import ProcessTabContent from '../../../../Shared/ProcessTabContent/ProcessTabContent';

const ContentGetVerified = () => {
    const tabContentTitle = 'Get verified';
    const tabContent =
        'It will take less than 5 minutes to get verified. Once the account is verified, you are ready to accept payments.';
    return (
        <>
            <ProcessTabContent title={tabContentTitle} content={tabContent} tabImg={tabImg} />
        </>
    );
};

export default ContentGetVerified;
