import icon from '../../../../../images/icon-link.svg';
import ProcessTab from '../../../../Shared/ProcessTab/ProcessTab';
import ProgressBar from '../../../../Shared/ProgressBar/ProgressBar';

const TabInvoiceLink = ({ isOpen }) => {
    const tabName = 'Share the link';
    return (
        <div className="tab-area">
            <ProcessTab name={tabName} icon={icon} />
            {isOpen === 3 && <ProgressBar />}
        </div>
    );
};

export default TabInvoiceLink;
