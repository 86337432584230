import icon from '../../../../../images/icon-receive.svg';
import ProcessTab from '../../../../Shared/ProcessTab/ProcessTab';
import ProgressBar from '../../../../Shared/ProgressBar/ProgressBar';

const TabPaymentReceive = ({ isOpen }) => {
    const tabName = 'Receive the payment';
    return (
        <div className="tab-area">
            <ProcessTab name={tabName} icon={icon} />
            {isOpen === 4 && <ProgressBar />}
        </div>
    );
};

export default TabPaymentReceive;
