import React from 'react';

const TermsPolicies = () => (
  <div className="container">
    <div className="content-page-area">
      <h2>Terms & Conditions</h2>

      <h3>1. Introduction</h3>
      <p>
        Welcome to Revorium. These Terms and Conditions ("Terms") govern your access to and
        use of our services, including our website, mobile applications, and any related services
        provided by Revorium (collectively referred to as the "Services"). By using our Services,
        you agree to comply with and be bound by these Terms. If you do not agree to these Terms,
        you may not use our Services.
      </p>

      <h3>2. Eligibility</h3>
      <p>
        To use our Services, you must be at least 18 years old and legally capable of entering into
        a binding contract. By using our Services, you represent and warrant that you meet these
        eligibility requirements.
      </p>

      <h3>3. Account Registration and Security</h3>
      <p><strong>Account Creation:</strong> To access our Services, you must create an account by
        providing accurate and complete information, including a valid email address, password, and
        any other required information.</p>
      <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality
        of your account credentials and for all activities that occur under your account. You agree
        to notify us immediately of any unauthorized use of your account or any other security breach.</p>
      <p><strong>Account Verification:</strong> After registration, you must verify your account by
        providing required documents such as a national ID card, passport, and proof of address. Failure
        to provide accurate and complete information may result in the suspension or termination of your
        account.</p>

      <h3>4. Use of Services</h3>
      <p><strong>Permitted Use:</strong> You may use our Services only for lawful purposes and in accordance
        with these Terms. You agree not to use our Services for any illegal or unauthorized purpose, to
        violate any applicable laws or regulations, or to infringe upon or violate our intellectual property
        rights or the rights of others.</p>
      <p><strong>Service Modifications:</strong> We reserve the right to modify, suspend, or discontinue any part
        of our Services at any time without notice.</p>

      <h3>5. Payment Processing</h3>
      <p><strong>Receiving Payments:</strong> Once your account is verified, you can receive payments through
        Revorium by generating a payment link or QR code, which can be shared with clients. Payments received
        will be reviewed by our compliance team and may take up to 48 hours to be approved.</p>
      <p><strong>Withdrawing Funds:</strong> You can withdraw funds from your Revorium account to a linked bank
        account. Withdrawal requests are processed based on the selected withdrawal type (regular or express)
        and are subject to applicable fees.</p>

      <h3>6. Service Creation</h3>
      <p><strong>Adding Services:</strong> You can add new services to your Revorium account by providing detailed
        service descriptions, selecting a relevant category, and uploading a service image. This information is
        crucial for our compliance team to review and approve your transactions.</p>
      <p><strong>Service Approval:</strong> All services added to your account are subject to review and approval
        by our compliance team. Revorium reserves the right to reject any service that does not meet our standards.</p>

      <h3>7. Non-Refundable Payment Policy</h3>
      <p><strong>7.1. Overview</strong></p>
      <p>By making a payment through Revorium, the buyer acknowledges and confirms that the agreed-upon services
        have been fully delivered and accepts that the payment is strictly non-refundable. This policy applies to
        all transactions conducted via Revorium, regardless of whether the service was provided through our platform
        or externally.</p>

      <p><strong>7.2 Confirmation of Service Delivery</strong></p>
      <p>Payment may only be made after the buyer has received and reviewed the services. By proceeding with the
        payment, the buyer confirms that the service has been delivered in accordance with the agreed terms, and
        no further action or fulfillment is required by the service provider. The buyer assumes full responsibility
        for confirming that the service has met their expectations prior to making the payment.</p>

      <p><strong>7.3 Agreement to Non-Refundability</strong></p>
      <p>By completing the payment, the buyer agrees to the following: the payment is final and irrevocable, with
        no option for refunds, disputes, or chargebacks, irrespective of the payment method (PayPal, card, etc.).</p>

      <p><strong>7.4 Liability Disclaimer</strong></p>
      <p>The buyer accepts full liability for confirming the receipt of services prior to payment. Revorium is not
        liable for any disputes arising from incomplete or unsatisfactory services after payment has been made, as
        the buyer's acceptance at checkout signifies acknowledgment of service completion.</p>

      <p><strong>7.5 Important Notice</strong></p>
      <p>If, as a buyer, you believe the service has not been delivered as agreed, do not proceed with the payment.
        Once the payment is processed, it will be considered as final confirmation that the service has been satisfactorily
        delivered, and no disputes or refunds will be entertained.</p>

      <h3>8. Referral Program</h3>
      <p><strong>Eligibility:</strong> To participate in our referral program, your account must be verified. Once verified,
        you will receive a referral code that can be shared with others.</p>
      <p><strong>Referral Rewards:</strong> You will earn a commission for each successful referral who completes the registration,
        verifies their account, and receives their first payment. The referral bonus will be credited to your Revorium account.</p>
      <p><strong>Tracking Referrals:</strong> You can track the status of your referrals through the referral tracking feature
        available in your account.</p>

      <h3>9. Fees</h3>
      <p><strong>Service Fees:</strong> We charge fees for certain transactions, including payment processing and withdrawals.
        The applicable fees will be displayed at the time of the transaction.</p>
      <p><strong>Changes to Fees:</strong> We reserve the right to change our fees at any time. Any changes to fees will be communicated
        to you in advance and will apply to future transactions.</p>

      <h3>10. Data Privacy</h3>
      <p><strong>Personal Data:</strong> We collect, use, and share your personal data in accordance with our Privacy Policy.
        By using our Services, you consent to the collection and use of your data as described in our Privacy Policy.</p>
      <p><strong>Security:</strong> We implement appropriate security measures to protect your personal data. However, we cannot
        guarantee the absolute security of your data.</p>

      <h3>11. Intellectual Property</h3>
      <p><strong>Ownership:</strong> All content, features, and functionality available on our platform, including but not limited
        to text, graphics, logos, and software, are the property of Revorium or its licensors and are protected by intellectual
        property laws.</p>
      <p><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to use our Services for personal
        or business purposes, in accordance with these Terms.</p>

      <h3>12. Termination</h3>
      <p><strong>Suspension and Termination:</strong> We reserve the right to suspend or terminate your account and access to our
        Services at our discretion, including but not limited to violations of these Terms, fraud, or other misconduct.</p>
      <p><strong>Effect of Termination:</strong> Upon termination, your right to use our Services will immediately cease, and any
        outstanding balances in your account may be forfeited.</p>

      <h3>13. Limitation of Liability</h3>
      <p><strong>No Warranties:</strong> Our Services are provided on an "as-is" and "as-available" basis. We do not warrant that our
        Services will be uninterrupted, error-free, or secure.</p>
      <p><strong>Liability Cap:</strong> To the maximum extent permitted by law, Revorium's liability for any claims arising from
        your use of the Services will be limited to the amount of fees paid by you in the 12 months preceding the claim.</p>

      <h3>14. Governing Law</h3>
      <p>These Terms and any disputes arising from your use of our Services will be governed by and construed in accordance with
        the laws of the jurisdiction in which Revorium is incorporated.</p>

      <p>If you have any questions about these Terms, please contact us at support@revorium.com.</p>
    </div>
  </div>
);

export default TermsPolicies;
