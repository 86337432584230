import tabImg from '../../../../../images/create-invoice.png';
import ProcessTabContent from '../../../../Shared/ProcessTabContent/ProcessTabContent';

const ContentCreateInvoice = () => {
    const tabContentTitle = 'Create an invoice';
    const tabContent = `Make customize invoices with a few clicks based on your client's need.`;
    return (
        <>
            <ProcessTabContent title={tabContentTitle} content={tabContent} tabImg={tabImg} />
        </>
    );
};

export default ContentCreateInvoice;
