import React from 'react';
import './ProcessTab.css';

const ProcessTab = ({ name, icon }) => {
    return (
        <div className="tab-box">
            <span className="tab-icon">
                <img src={icon} alt="" />
            </span>
            <span className="tab-name">{name}</span>
        </div>
    );
};

export default ProcessTab;
