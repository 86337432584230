import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './App';
import './index.css';

const tagManagerArgs = {
    gtmId: 'GTM-P5BZCR7Q',
};

TagManager.initialize(tagManagerArgs);
const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactGA.initialize('G-SFHZ3Y4WQ6');

window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'esblqewo',
});

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// const SendAnalytics = () => {
//     ReactGA.send({
//         hitType: 'pageview',
//         page: window.location.pathname,
//     });
// };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-undef
reportWebVitals(SendAnalytics);
