import cardImg from '../../images/app2.png';
import chatIcon from '../../images/chat-icon.svg';
import CardStyleTwo from '../Shared/CardStyleTwo/CardStyleTwo';
import './Support.css';

const Support = () => {
    const widget = {
        cardTitle: 'Start earning now as a freelancer',
        cardImg: cardImg,
        direction: 'row',
        cardBG: '#ffffff',
        cardHColor: '#040415',
        cardPColor: '#04041599',
    };

    return (
        <>
            <div className="container">
                <div className="support-title">
                    <h3>Contact us 24/7</h3>
                    <p>
                        We’re happy to answer any questions you may have, just send us a message via
                        our email or call us via phone number.
                    </p>
                </div>
                <div className="section-margin">
                    <div className="support-main">
                        <div className="support">
                            <div>
                                <img width="80px" src={chatIcon} alt="" />
                            </div>
                            <h3>Email Support</h3>
                            <p>If you have any questions, please reach out to us via email.</p>
                            <div className="link-btn-div">
                                <a
                                    href="mailto:support@revorium.com"
                                    className="link-btn"
                                    style={{ background: '#5947FD' }}
                                >
                                    Email Now
                                </a>
                            </div>
                        </div>
                        {/* <div className="support">
                            <div>
                                <img width="80px" src={phoneIcon} alt="" />
                            </div>
                            <h3>Phone Call</h3>
                            <p>If you have any questions, please call us +8801949764308</p>
                            <div className="link-btn-div">
                                <a
                                    className="link-btn"
                                    href="tel:+8801949764308"
                                    style={{ background: 'rgba(255, 216, 156, 0.26)' }}
                                >
                                    Call Now
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <CardStyleTwo widget={widget} />
        </>
    );
};

export default Support;
