import React from 'react';
import cardImg from '../../../../images/payment-card3.png';
import CardStyleOne from '../../../Shared/CardStyleOne/CardStyleOne';

const CardWidget3 = () => {
    const widget = {
        cardTitle: 'Connect your local bank accounts',
        cardContent:
            'Connect your local bank accounts and withdraw the money within a few minutes. Get notified to receive the highest exchange rate.',
        cardImg: cardImg,
        direction: 'row',
        cardBG: 'rgba(255, 232, 196, 0.39)',
        cardHColor: '#040415cc',
        cardPColor: '#04041599',
    };

    const linkBtn = {
        text: 'Get Started',
        btnBG: '#5947FD',
        btnColor: '#040415',
        link: 'https://revoweb.web.app/',
    };

    return <CardStyleOne widget={widget} cardImg={cardImg} linkBtn={linkBtn} />;
};

export default CardWidget3;
