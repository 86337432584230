import applePayIcon from '../../../images/apple-pay.png';
import cardPayIcon from '../../../images/card-pay.png';
import googlePayIcon from '../../../images/google-pay.png';
import paypalPayIcon from '../../../images/paypal-pay.png';
import Htitle from '../../Shared/Htitle/Htitle';
import './PaymentMethod.css';

const PaymentMethod = () => {
    const title = 'Let your clients choose how they want to pay';

    return (
        <div className="payment-method-area">
            <div className="payment-method-title">
                <Htitle title={title} />
            </div>
            <div className="container">
                <div className="payment-methods">
                    <div>
                        <img src={applePayIcon} alt="" />
                    </div>
                    <div>
                        <img src={googlePayIcon} alt="" />
                    </div>
                    <div>
                        <img src={paypalPayIcon} alt="" />
                    </div>
                    <div>
                        <img src={cardPayIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethod;
