import React from 'react';
import LinkButton from '../LinkButton/LinkButton';
import './CardStyleOne.css';

const CardStyleOne = ({ widget, linkBtn }) => {
    let { cardTitle, cardContent, cardImg, cardBG, cardHColor, cardPColor, direction } = widget;
    let { text, btnBG, btnColor, link } = linkBtn;

    return (
        <div className="card-style-one">
            <div className="container">
                <div className="card-area" style={{ background: cardBG, flexDirection: direction }}>
                    <div className="card-style-left">
                        <h2 style={{ color: cardHColor }}>{cardTitle}</h2>
                        <p style={{ color: cardPColor }}>{cardContent}</p>
                        <LinkButton text={text} bg={btnBG} color={btnColor} link={link} />
                    </div>
                    <div className="card-style-right">
                        <img alt="img" src={cardImg} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardStyleOne;
