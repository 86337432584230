import React from 'react';
import cardImg from '../../../images/hassle-free.png';
import CardStyleOne from '../../Shared/CardStyleOne/CardStyleOne';
import Htitle from '../../Shared/Htitle/Htitle';

const CardWidget1 = () => {
    const title = 'Accept payments without any hassle';
    const subTitle =
        'Instant payment without borders globally for freelancers, remote workers, and anyone who needs to get paid, and receive money in their native currency.';

    const widget = {
        cardTitle: 'Hassle-free payment method for your valuable customers',
        cardContent:
            'Hassle-free payment method for your valuable customers. With Revorium your clients can pay you through Google pay, Apple pay, Paypal, Mastercard, and other payment methods without sharing their information.',
        cardImg: cardImg,
        direction: 'row',
        cardBG: '#d7e2f1',
        cardHColor: '#040415cc',
        cardPColor: '#04041599',
    };

    const linkBtn = {
        text: 'Learn More',
        btnBG: '#ffffff',
        btnColor: '#040415',
        link: '/hassle-free',
    };

    return (
        <>
            <Htitle title={title} subTitle={subTitle} />
            <CardStyleOne widget={widget} cardImg={cardImg} linkBtn={linkBtn} />
        </>
    );
};

export default CardWidget1;
