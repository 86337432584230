import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../images/logo.svg';
import './Header.css';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [isDesktop, setIsDesktop] = useState();
    const appUserURL = process.env.REACT_APP_USER_APP_URL;
    // Custom function
    function switch_menu() {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        window.innerWidth > 767 ? setIsDesktop(true) : setIsDesktop(false);
    }, []);

    window.addEventListener('resize', () => {
        window.innerWidth > 767 ? setIsDesktop(true) : setIsDesktop(false);
    });

    return (
        <>
            <div className="nav_bar">
                {!isDesktop && (
                    <div className="mobile-menu">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="" />
                            </Link>
                        </div>
                        <div className="menu_switch">
                            <i
                                onClick={switch_menu}
                                className={`${showMenu ? 'fas fa-times' : 'fas fa-bars'}`}
                            />
                        </div>
                    </div>
                )}
                <div className="header-section">
                    <div className="header-area container">
                        <div className={`nav_container ${showMenu ? 'menu_active' : null}`}>
                            <div className="logo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                            <ul className="menu_items">
                                <li>
                                    <Link to="/" onClick={switch_menu}>
                                        Home
                                    </Link>
                                </li>
                                {/* <li>
                                    <NavLink
                                        to="/personal"
                                        className={({ isActive }) =>
                                            isActive ? 'active-class' : 'no-active-class'
                                        }
                                        onClick={switch_menu}
                                    >
                                        Personal
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        to="/payment"
                                        className={({ isActive }) =>
                                            isActive ? 'active-class' : 'no-active-class'
                                        }
                                        onClick={switch_menu}
                                    >
                                        Payment
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <a>Payments</a>

                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink
                                                to="/payment"
                                                className={({ isActive }) =>
                                                    isActive ? 'active-class' : 'no-active-class'
                                                }
                                                onClick={switch_menu}
                                            >
                                                Accept fiat payment
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/crypto-payment"
                                                className={({ isActive }) =>
                                                    isActive ? 'active-class' : 'no-active-class'
                                                }
                                                onClick={switch_menu}
                                            >
                                                Accept crypto payment
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li> */}
                                <li>
                                    <NavLink
                                        to="/support"
                                        className={({ isActive }) =>
                                            isActive ? 'active-class' : 'no-active-class'
                                        }
                                        onClick={switch_menu}
                                    >
                                        Support
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right">
                            <a href={appUserURL} className="btn-login">
                                Log In
                            </a>
                            <a href={appUserURL} className="btn-signup">
                                Sign Up
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
