import React from 'react';

const ReceivingPayment = () => {
    return (
        <div className="container">
            <div className="content-page-area">
                <h2>Get a little more than just receiving payments with Revorium</h2>
                <p>
                    Do not let the clients leave your websites to pay for your services and
                    products; in most of cases you end up losing your valuable customers. Accept
                    payment from your website with different payment methods that suit your clients
                    with Revorium’s global payment API. Read the payment API integration guide.
                </p>
                <p>
                    Let us take care of all your worries about how can I get paid by my foreign
                    clients when they want to pay you by their own payment gateway platform. It’s a
                    never-ending problem for freelancers from different parts of the world.
                    Freelancers lose five out of ten clients from abroad only because of not having
                    an account in the payment platform that the client use. The main reason for that
                    is that prospective customers do not want to pay the freelancers by registering
                    in a new payment platform by providing all the credentials that they will never
                    use in their everyday life except for paying the freelancers once or twice to
                    get the service. Also, It’s a hassle for clients who are really busy with their
                    businesses and other things in life. Existing payment wallets that are not made
                    for the freelancers and are not focused on localization but freelancers have to
                    use end up paying huge charges, bad customer support, extremely low exchange
                    rates, payment delays, and multiple restrictions in the account while
                    withdrawing larger amounts.
                </p>
                <p>
                    Revorium solves all these problems for freelancers and has made a hassle-free
                    payment gateway for clients where they can pay for the services received from
                    the freelancers with just two clicks on the payment link. First, click to see
                    the invoice details and choose the payment method, and second click for
                    confirmation to process the payment. That’s it. Money is in your wallet. Isn’t
                    it what we all want for a long time as a freelancer?
                </p>
            </div>
        </div>
    );
};

export default ReceivingPayment;
