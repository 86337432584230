import UpcomingPartners from '../../Shared/UpcomingPartners/UpcomingPartners';
import CardWidget1 from '../CardWidget1/CardWidget1';
import CardWidget2 from '../CardWidget2/CardWidget2';
import CardWidget3 from '../CardWidget3/CardWidget3';
import Hero from '../Hero/Hero';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import WorkProcess from '../WorkProcess/WorkProcess';
import './Home.css';

const Home = () => {
    return (
        <>
            <div className="section-margin">
                <Hero />
            </div>
            <div className="section-margin">
                <PaymentMethod />
            </div>
            <UpcomingPartners />
            <WorkProcess />
            <div className="section-margin">
                <CardWidget1 />
                <br />
                <br />
                <CardWidget2 />
            </div>

            <CardWidget3 />
        </>
    );
};

export default Home;
