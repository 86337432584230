import React from 'react';
import icon from '../../../../../images/icon-invoice.svg';
import ProcessTab from '../../../../Shared/ProcessTab/ProcessTab';
import ProgressBar from '../../../../Shared/ProgressBar/ProgressBar';

const TabCreateInvoice = ({ isOpen }) => {
    const tabName = 'Create an invoice';
    return (
        <div className="tab-area">
            <ProcessTab name={tabName} icon={icon} />
            {isOpen === 2 && <ProgressBar />}
        </div>
    );
};

export default TabCreateInvoice;
