import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="container">
                <div className="content-page-area">
                    <h2>Privacy Policy</h2>
                    <p>
                        It’s important to us that you have freedom of choice and control in how we
                        handle your personal data and information. All our products are designed and
                        tested extensively by our privacy team before giving it to our users, so you
                        can expect privacy by default.
                    </p>
                    <strong>Your data</strong>
                    <p>
                        Your personal data belongs to you and you have the right to understand and
                        control how it’s used.
                    </p>
                    <strong>Your Rights</strong>
                    <p>
                        If you want to exercise your rights over your personal data, contact us any
                        time at privacy@revorium.com
                    </p>
                    <strong>Clear and transparent</strong>
                    <p>
                        If you share data with us, we’ll be transparent about how we’re using it. We
                        clearly and simply explain how we collect and use your personal data in our
                        privacy notices.
                    </p>
                    <strong>Your right to choose</strong>
                    <p>
                        We give you clear choices and simple tools to exercise your rights. If we
                        collect data, we’ll tell you why. Adjust your preferences web app any time.
                    </p>
                    <strong>Keeping your data safe</strong>
                    <p>
                        We deploy industry standard and bank-grade security, which is independently
                        tested and audited to keep your personal data secure and protected. We will
                        never sell your personal data
                    </p>
                    <strong>Customer-focused innovation</strong>
                    <p>
                        When we collect and use your data, it’s always in the interest of delivering
                        exceptional services and improving your experience
                    </p>
                    <strong>We are committed to protecting and respecting your privacy. </strong>
                    <p>
                        <label>We will:</label>
                        <label>- always keep your personal data safe and private</label>
                        <label>- never sell your personal data</label>
                        <label>- allow you to manage your marketing choices at any time</label>
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
