// import { RiFacebookFill, RiLinkedinFill } from 'react-icons/ri';
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.svg';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-left">
                        <div>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="footer-info">
                            <p>Revorium OÜ</p>
                            <p>Registry no: 16847017</p>
                            <p>Karu 17, 10120 Tallinn, Estonia</p>
                            <p>Revorium is a financial technology company, not a </p>
                            <p>bank or depository institution.</p>
                            <p>All transactions are facilitated through our partner banks </p>
                            <p>in Europe and the USA.</p>
                        </div>
                        <div className="social-icon">
                            <ul>
                                <li>
                                    <a
                                        target="__blank"
                                        href="https://www.facebook.com/revoriumsoftcent"
                                    >
                                        <FaFacebook />
                                    </a>
                                </li>

                                <li>
                                    <a
                                        target="__blank"
                                        href="https://www.linkedin.com/company/revorium/about/"
                                    >
                                        <FaLinkedinIn />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="footer-nav">
                        <h4>Personal</h4>
                        <ul>
                            <li>
                                <Link to="/personal">Get paid</Link>
                            </li>
                            <li>
                                <Link to="/personal">Accept payment</Link>
                            </li>
                            <li>
                                <Link to="/personal">Request money</Link>
                            </li>
                            <li>
                                <Link to="/personal">Cards</Link>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className="footer-nav">
                        <h4>Payments</h4>
                        <ul>
                            <li>
                                <Link to="/fiat-payment">Accept fiat payment</Link>
                            </li>
                            <li>
                                <Link to="/crypto-payment">Accept crypto payment</Link>
                            </li>
                        </ul>
                    </div> */}
                    <div className="footer-nav">
                        <h4>24/7 Support</h4>
                        <ul>
                            <li> support@revorium.com</li>
                        </ul>
                    </div>
                    <div className="footer-nav">
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>
                                <Link to="/terms-conditions">Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/cookie-policy">Cookie Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-nav">
                        <h4>Help</h4>
                        <ul>
                            <li>
                                <Link to="/support">Support</Link>
                            </li>
                            <li>
                                <Link to="/faq">FAQs</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
