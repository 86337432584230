import React from 'react';
import cardImg from '../../../../images/payment-card1.png';
import CardStyleOne from '../../../Shared/CardStyleOne/CardStyleOne';
import Htitle from '../../../Shared/Htitle/Htitle';

const CardWidget1 = () => {
    const title = 'Fastest payment system';
    const subTitle = 'Make day-to-day spending a breeze with all things money in one place.';

    const widget = {
        cardTitle: 'Request money the way you want by creating your customize invoices',
        cardContent:
            'Create your customize invoice based on your needs without annoying your clients by asking for their credentials or by indirectly forcing them to register in a new payment wallet where they need to enter their personal details.',
        cardImg: cardImg,
        direction: 'row',
        cardBG: '#d7e2f1',
        cardHColor: '#040415cc',
        cardPColor: '#04041599',
    };

    const linkBtn = {
        text: 'Get Started',
        btnBG: '#ffffff',
        btnColor: '#040415',
        link: 'https://revoweb.web.app/',
    };

    return (
        <>
            <Htitle title={title} subTitle={subTitle} />
            <CardStyleOne widget={widget} cardImg={cardImg} linkBtn={linkBtn} />
        </>
    );
};

export default CardWidget1;
