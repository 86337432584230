import React from 'react';
import cardImg from '../../../../images/payment-card2.png';
import CardStyleOne from '../../../Shared/CardStyleOne/CardStyleOne';

const CardWidget2 = () => {
    const widget = {
        cardTitle: 'Get paid with a tap from anyone, anywhere',
        cardContent:
            'Request money from friends and family around the world, and they can pay with a tap using Apple or Google Pay. No fees, just ease.',
        cardImg: cardImg,
        direction: 'row-reverse',
        cardBG: '#5947FD',
        cardHColor: '#040415cc',
        cardPColor: '#04041599',
    };

    const linkBtn = {
        text: 'Get Started',
        btnBG: 'rgba(254, 153, 0, 0.5)',
        link: 'https://revoweb.web.app/',
    };

    return (
        <>
            <CardStyleOne widget={widget} linkBtn={linkBtn} />
        </>
    );
};

export default CardWidget2;
