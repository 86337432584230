import icon from '../../../../../images/icon-user.svg';
import ProcessTab from '../../../../Shared/ProcessTab/ProcessTab';
import ProgressBar from '../../../../Shared/ProgressBar/ProgressBar';

const TabCreateAccount = ({ handleClick, isOpen }) => {
    const tabName = 'Create an account';
    return (
        <div className="tab-area" /* onClick={() => handleClick(0)} */>
            <ProcessTab name={tabName} icon={icon} />
            {isOpen === 0 && <ProgressBar />}
        </div>
    );
};

export default TabCreateAccount;
