import React from 'react';

const HassleFree = () => {
    return (
        <div className="container">
            <div className="content-page-area">
                <h2>Hassle free payment method</h2>
                <p>
                    Hassle-free payment method for your valuable customers. With Revorium your
                    clients can pay you through Google pay, Apple pay, Paypal, Mastercard, and other
                    payment methods without sharing their information. Freelancer will get a
                    one-stop solution on Revorium to receive money from clients around the globe.
                    Clients will feel comfortable paying the payment since they can pay through
                    Paypal and from their preferred method of payment.
                </p>
                <p>
                    Let us take care of all your worries about how can I get paid by my foreign
                    clients when they want to pay you by their own payment gateway platform. It’s a
                    never-ending problem for freelancers from different parts of the world.
                    Freelancers lose five out of ten clients from abroad only because of not having
                    an account in the payment platform that the client use. The main reason for that
                    is that prospective customers do not want to pay the freelancers by registering
                    in a new payment platform by providing all the credentials that they will never
                    use in their everyday life except for paying the freelancers once or twice to
                    get the service. Also, It’s a hassle for clients who are really busy with their
                    businesses and other things in life. Existing payment wallets that are not made
                    for the freelancers and are not focused on localization but freelancers have to
                    use end up paying huge charges, bad customer support, extremely low exchange
                    rates, payment delays, and multiple restrictions in the account while
                    withdrawing larger amounts.
                </p>
                <p>
                    Revorium solves all these problems for freelancers and has made a hassle-free
                    payment gateway for clients where they can pay for the services received from
                    the freelancers with just two clicks on the payment link. First, click to see
                    the invoice details and choose the payment method, and second click for
                    confirmation to process the payment. That’s it. Money is in your wallet. Isn’t
                    it what we all want for a long time as a freelancer?
                </p>
            </div>
        </div>
    );
};

export default HassleFree;
