import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import partner1 from '../../../images/partner1.png';
import partner2 from '../../../images/partner2.png';
import partner3 from '../../../images/partner3.png';
import partner4 from '../../../images/partner4.png';
import partner5 from '../../../images/partner5.png';
import partner6 from '../../../images/partner6.png';
import partner7 from '../../../images/partner7.png';
import partner8 from '../../../images/partner8.png';
import Htitle from '../Htitle/Htitle';
import './UpcomingPartners.css';

const UpcomingPartners = () => {
    const title = 'Upcoming partners';
    const subTitle = '';
    return (
        <div className="section-margin">
            <div className="partners-section">
                <Htitle title={title} subTitle={subTitle} />
                <div className="container">
                    <div>
                        <Swiper
                            breakpoints={{
                                320: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 4,
                                },
                            }}
                            modules={[Navigation, Autoplay]}
                            className="tech-logo"
                            spaceBetween={30}
                            slidesPerView={3}
                            navigation={false}
                            autoplay
                        >
                            <SwiperSlide>
                                <img src={partner1} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner2} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner3} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner4} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner5} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner6} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner7} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={partner8} alt="" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpcomingPartners;
