import React from 'react';
// import { AiFillApple } from 'react-icons/ai';
// import { FcGoogle } from 'react-icons/fc';

import './CardStyleTwo.css';

const appUserURL = process.env.REACT_APP_USER_APP_URL;

const CardStyleTwo = ({ widget, linkBtn }) => {
    let { cardTitle, cardContent, cardImg, cardBG, cardHColor, cardPColor, direction } = widget;

    return (
        <div className="card-style-two">
            <div className="container">
                <div className="card-area" style={{ background: cardBG, flexDirection: direction }}>
                    <div className="card-style-left">
                        <h2 style={{ color: cardHColor }}>{cardTitle}</h2>
                        <p style={{ color: cardPColor }}>{cardContent}</p>
                        <div className="play-store-btn-area">
                            <a
                                target="_blank"
                                href={appUserURL}
                                className="play-store-btn"
                                rel="noreferrer"
                            >
                                {/* <span className="store-icon">
                                    <AiFillApple />
                                </span> */}
                                {/* <span>Apple Store</span> */}
                                <span>Open a free account</span>
                            </a>
                            {/* <a className="play-store-btn">
                                <span className="store-icon">
                                    <FcGoogle />
                                </span>
                                <span>Google Play</span>
                            </a> */}
                        </div>
                    </div>
                    <div className="card-style-right">
                        <img src={cardImg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardStyleTwo;
