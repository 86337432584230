import tabImg from '../../../../../images/create-account.png';
import ProcessTabContent from '../../../../Shared/ProcessTabContent/ProcessTabContent';

const ContentCreateAccount = () => {
    const tabContentTitle = 'Create an account';
    const tabContent =
        'Download the app from App Store and Google Play Store. Sign up with the required details.';
    return (
        <>
            <ProcessTabContent title={tabContentTitle} content={tabContent} tabImg={tabImg} />
        </>
    );
};

export default ContentCreateAccount;
