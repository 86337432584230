import React from 'react';
import icon from '../../../../../images/icon-verified.svg';
import ProcessTab from '../../../../Shared/ProcessTab/ProcessTab';
import ProgressBar from '../../../../Shared/ProgressBar/ProgressBar';

const TabGetVerified = ({ isOpen }) => {
    const tabName = 'Get verified';

    return (
        <div className="tab-area">
            <ProcessTab name={tabName} icon={icon} />
            {isOpen === 1 && <ProgressBar />}
        </div>
    );
};

export default TabGetVerified;
