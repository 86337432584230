import React from 'react';

const Personal = () => {
    return (
        <div className="container">
            <div className="content-page-area">
                <h2>Personal</h2>

                <strong>Get paid</strong>
                <p>
                    It has never been easy to get paid for your work or services while the client is
                    from abroad or you are a freelancer working in different marketplaces. Multiple
                    things come into the scene when it’s about accepting or getting paid by a
                    client. 1. Client doesn’t have an account in the platform that you are asking
                    for the payment, 2. Client’s unwillingness to register on an unknown platform,
                    3. Currency conversion rates and hidden fees, 4. Withdrawal in local bank
                    accounts and 5. Restrictions on different amount limits to charge multiple times
                    and more.. We have worked so hard to solve all these problems at Revorium and
                    make an international payment just like your local payments instantly, with no
                    hidden fees, no limitations, and hassle-free transactions. Get paid by anyone
                    from anywhere in the world for your services in no time.
                </p>
                <strong>Accept payment</strong>
                <p>
                    Accept payment from different freelancing marketplace platforms without ever
                    wondering and getting annoyed by the huge fees, hidden charges, and long payment
                    processing time. We are working very closely with our partners to make sure that
                    freelancers get their fair value for their hard work without any hassle so that
                    you as a freelancer can focus on your work and enjoy your financial freedom.
                </p>
                <strong>Request money</strong>
                <p>
                    Create your customize invoice based on your needs without annoying your clients
                    by asking for their credentials or by indirectly forcing them to register in a
                    new payment wallet where they need to enter their personal details. Enter the
                    amount that you have agreed with your client and describe the services in a few
                    words, create the invoice and share the link with your valuable customer and now
                    he will be able to pay you just by tapping the link by choosing his/her
                    preferred methods of payment. That’s it. Isn’t it awesome? Happy clients; happy
                    you; more growth and more success.
                </p>
                <strong>Cards</strong>
                <p>
                    Now you have the access to the international visa and mustard physical and
                    virtual cards. Customize your cards based on your needs. Enjoy your holiday with
                    family and friends; spend the way you want anywhere in the world and in your
                    local currency. No extra charges, and no hidden fees in the currency exchange
                    rates. Get cashback instantly from our partners while buying products or
                    services. See how it works.
                </p>
            </div>
        </div>
    );
};

export default Personal;
