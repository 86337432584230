import React, { useEffect, useState } from 'react';
import ContentCreateAccount from './Content/ContentCreateAccount/ContentCreateAccount';
import ContentCreateInvoice from './Content/ContentCreateInvoice/ContentCreateInvoice';
import ContentGetVerified from './Content/ContentGetVerified/ContentGetVerified';
import ContentInvoiceLink from './Content/ContentInvoiceLink/ContentInvoiceLink';
import ContentPaymentReceive from './Content/ContentPaymentReceive/ContentPaymentReceive';
import TabCreateAccount from './Tabs/TabCreateAccount/TabCreateAccount';
import TabCreateInvoice from './Tabs/TabCreateInvoice/TabCreateInvoice';
import TabGetVerified from './Tabs/TabGetVerified/TabGetVerified';
import TabInvoiceLink from './Tabs/TabInvoiceLink/TabInvoiceLink';
import TabPaymentReceive from './Tabs/TabPaymentReceive/TabPaymentReceive';
import './WorkProcess.css';

const WorkProcess = () => {
    const [i, setI] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setI(i + 1);
            if (i === 4) {
                setI(0);
            }
        }, 3000);
    }, [i]);

    const handleClick = (x) => {
        setI(x);
    };

    return (
        <div className="section-margin">
            <div className="work-process">
                <div className="container">
                    <h2>How does it work</h2>
                    <div className="process-tabs">
                        <TabCreateAccount handleClick={handleClick} isOpen={i} />
                        <TabGetVerified isOpen={i} />
                        <TabCreateInvoice isOpen={i} />
                        <TabInvoiceLink isOpen={i} />
                        <TabPaymentReceive isOpen={i} />
                    </div>
                    <div className="process-tab-content">
                        {i === 0 && <ContentCreateAccount />}
                        {i === 1 && <ContentGetVerified />}
                        {i === 2 && <ContentCreateInvoice />}
                        {i === 3 && <ContentInvoiceLink />}
                        {i === 4 && <ContentPaymentReceive />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkProcess;
