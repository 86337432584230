import React from 'react';
import cardImg from '../../../images/app2.png';
import CardStyleTwo from '../../Shared/CardStyleTwo/CardStyleTwo';

const CardWidget3 = () => {
    const widget = {
        cardTitle: 'Get paid instantly in your local bank account',
        // cardContent:'Download the app from App Store and Google Play Store. Sign up with the required details.',
        cardImg: cardImg,
        direction: 'row',
        cardBG: '#ffffff',
        cardHColor: '#040415',
        cardPColor: '#04041599',
    };

    return (
        <>
            <CardStyleTwo widget={widget} />
        </>
    );
};

export default CardWidget3;
