import React from 'react';
import './Htitle.css';

const Htitle = ({ title, subTitle }) => {
    return (
        <div className="container">
            <div className="title-area">
                <h2>{title}</h2>
                {subTitle ? <p>{subTitle}</p> : ''}
            </div>
        </div>
    );
};

export default Htitle;
