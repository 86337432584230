import tabImg from '../../../../../images/receved- payments.png';
import ProcessTabContent from '../../../../Shared/ProcessTabContent/ProcessTabContent';

const ContentPaymentReceive = () => {
    const tabContentTitle = 'Receive payments';
    const tabContent = `Share the invoice link with your customers, they will be able to pay just by tapping the link with their preferred payment methods instantly.`;
    return (
        <>
            <ProcessTabContent title={tabContentTitle} content={tabContent} tabImg={tabImg} />
        </>
    );
};

export default ContentPaymentReceive;
