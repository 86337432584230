import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CookiePolicy from './components/Content/CookiePolicy/CookiePolicy';
import Faq from './components/Content/Faq/Faq';
import HassleFree from './components/Content/HassleFree/HassleFree';
import Payments from './components/Content/Payments/Payments';
import Personal from './components/Content/Personal/Personal';
import PrivacyPolicy from './components/Content/PrivacyPolicy/PrivacyPolicy';
import ReceivingPayment from './components/Content/ReceivingPayment/ReceivingPayment';
import TermsPolicies from './components/Content/TermsPolicies/TermsPolicies';
import Home from './components/Home/Home/Home';
import Footer from './components/Shared/Footer/Footer';
import Header from './components/Shared/Header/Header';
import Support from './components/Support/Support';

function App() {
    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = '//code.tidio.co/wrpenvehihwojze0sjz3mp1nr5vgaxay.js';
    //     script.async = true;

    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);
    return (
        <>
            <BrowserRouter>
                <Header />
                <div className="main">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/personal" element={<Personal />} />
                        <Route path="/terms-conditions" element={<TermsPolicies />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/cookie-policy" element={<CookiePolicy />} />
                        <Route path="/payment" element={<Payments />} />
                        {/* <Route path="/crypto-payment" element={<CryptoPayment />} /> */}
                        <Route path="/hassle-free" element={<HassleFree />} />
                        <Route path="/receiving-payment" element={<ReceivingPayment />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/faq" element={<Faq />} />
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
