import tabImg from '../../../../../images/invoice-share.png';
import ProcessTabContent from '../../../../Shared/ProcessTabContent/ProcessTabContent';

const ContentInvoiceLink = () => {
    const tabContentTitle = 'Share the link';
    const tabContent = `Request money to get paid in your wallet just by sharing your invoice link with your clients.`;
    return (
        <>
            <ProcessTabContent title={tabContentTitle} content={tabContent} tabImg={tabImg} />
        </>
    );
};

export default ContentInvoiceLink;
